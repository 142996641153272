<template>
  <Modal class="unlock" ref="modal" @close="closeSuccess" v-if="modal === 0" :settings="settings">
    <UnlockSuccess :autoclose="3000" @autoclosed="closeSuccess" />
  </Modal>
  <Modal class="unlock" ref="modal" @close="closeUnlock" v-else-if="modal === 1" :settings="settings">
    <div class="unlock-creator" slot="header" v-if="creator">
      <div class="avatar">
        <figure class="picture" :style="`--avatar:url('${creator.picture}');`"></figure>
      </div>
      <div class="info">
        <p class="name">{{ creator.name }}</p>
        <p class="user">@{{ creator.user }}</p>
      </div>
    </div>
    <div class="unlock-content" v-if="creator">
      <div v-html="normalizeText($locale['object']['subscribe']['unlock-message'])"></div>
      <div class="price">
        <p class="price-label">{{ getPrice(post) }} {{ $locale["object"]["user-profile"]["credits-label"] }}</p>
      </div>
      <div class="options">
        <UnlockButton :loading="$lockapp" @click="unlock" :label="`/ ${netPrice} ${$locale['object']['user-profile']['credits-label']}`" />
      </div>
    </div>
  </Modal>
  <Modal class="unlock" ref="modal" @close="closeUnlock" v-else-if="modal === 2" :settings="settings">
    <UnlockNotFound :post="post" @credits="getCredits" />
  </Modal>
</template>

<script>
import Modal from "../widgets/Modal.vue";
import UnlockButton from "../buttons/UnlockButton.vue";
import UnlockNotFound from "./UnlockNotFound.vue";
import UnlockSuccess from "./UnlockSuccess.vue";
import PrimaryButton from "../buttons/PrimaryButton.vue";
export default {
  components: { Modal, UnlockButton, UnlockNotFound, PrimaryButton, UnlockSuccess },
  data: function() {
    return {
      modal: 1,
      creator: null,
      post: null,
      netPrice: null,
      settings: {
        animation: "midTopIn",
      },
    };
  },
  methods: {
    isLoading: function(state) {
      this.$store.state.lockapp = state;
    },
    getPrice: function() {
      const discountstatus = this.post?.discountStatus;
      const discountPercent = this.post?.discountPercent;
      const discountDateLimit = this.post?.discountDateLimit;
      const isTimeLeft = this.$global.isTimeLeft(discountDateLimit);
      if (!isTimeLeft) {
        this.netPrice = this.post?.price;
        return this.netPrice;
      } else if (discountstatus === "on") {
        this.netPrice = (this.post?.price * discountPercent) / 100;
        return this.netPrice;
      } else {
        this.netPrice = this.post?.price;
        return this.netPrice;
      }
    },
    getCredits: function() {
      this.$refs.modal.close();
      this.$router.push(this.$links["my-credits"]);
    },
    closeUnlock: function() {
      this.$store.state.unlock = false;
    },
    closeSuccess: function() {
      const id = this.post?._id;
      this.closeUnlock();
      this.$router.push("/post/" + id);
    },
    normalizeText: function(text) {
      return text.replaceAll("$creator", `<b>@${this.creator.user}</b>`);
    },
    unlock: function() {
      this.isLoading(true);
      const _data = { creator: this.creator?._id, post: this.post?._id };
      const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "posts/unlock", _data);
      sedData.then((response) => this.unlockSuccess(response));
      sedData.catch((response) => this.unlockError(response));
    },
    unlockSuccess: function(response) {
      this.isLoading(false);
      if (response?.data?.success) {
        this.modal = 0;
        this.$store.dispatch("getProfile");
        this.ialert.close();
      } else if (response?.data?.reason === 1) {
        this.modal = 2;
        this.ialert.close();
      } else {
        this.$refs.modal.close();
        this.ialert.go({ message: this.normalizeText(response?.data?.message), unique: true, title: "Error" });
      }
    },
    unlockError: function(response) {
      this.isLoading(false);
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
  },
  watch: {
    "$store.state.unlock": function(state) {
      this.creator = state?.user;
      this.post = state;
      if (state) {
        this.modal = 1;
        this.$refs.modal.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$avatar_size: 56px;
.unlock {
  &-creator {
    @include Flex(inherit, center, center);
    position: relative;
    padding: $mpadding $mpadding/2 $mpadding $mpadding;
    .avatar {
      margin: 0 $mpadding/2 0 0;
      .picture {
        @include BgImage();
        width: $avatar_size;
        height: $avatar_size;
        background-image: var(--avatar);
        border-radius: 50%;
      }
    }
    .info {
      .name {
        font-size: 110%;
        font-weight: bold;
      }
      .user {
        color: $emperor;
      }
    }
  }
  &-content {
    text-align: center;
    .price {
      margin: $mpadding 0;
      &-label {
        font-size: 110%;
        font-weight: bold;
        color: $primary_color;
      }
    }
  }
}
</style>
