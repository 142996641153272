<template>
  <div class="subscribe-content" v-if="post">
    <div class="not-found" v-html="`${$locale['buy-subscribe-not-found']}`"></div>
    <div class="more-credits">
      <div class="more-credits-label">{{ $locale["object"]["subscribe"]["subscribe-more-credits"] }}</div>
      <div class="more-credits-options">
        <PrimaryButton :label="$locale['object']['user-profile']['reload-credits-label']" @click="$emit('credits')" />
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "../buttons/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  props: ["post"],
};
</script>
